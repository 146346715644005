export default function RefundPolicy() {
  const style = {
    noteBoxe: {
      border: "1px solid",
      borderRadius: "5px",
      padding: "10px",
      margin: "2rem 0 0 0",
      borderColor: "#E76F51",
      backgroundColor: "rgba(231, 111, 81, 0.1)",
    },
  };
  return (
    <div
      className="br-r-1 bg-white m-a h-100"
      style={{
        overflow: "auto",
        maxWidth: "896px",
        padding: "2rem 1.5rem",
        boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
      }}
    >
      <h1 className="fs-20px b-500">
        IndSri Ferry - Connecting Cultures, Bridging Palk Strait
      </h1>
      <div>
        <p style={style.noteBoxe}>
          ⚠️ &nbsp; Providing incorrect information by the customer and/or
          travel agents, such as wrong passport information, including spellings
          at the time of bookings and visa details, will result in a correction
          fee of{" "}
          <span className="b-700">₹1,000/- or LKR 3,500/- or USD 12</span> at
          the time of check-in!
        </p>
      </div>
      <ul className="">
        <br></br>
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>
          Refund Policy
        </li>
        <li className="mt-1-5">
          Cancellation 72 hours or more before departure: 80% of the ticket
          amount will be refunded.
        </li>
        <li className="mt-1-5">
          Cancellation between 24 hours to 72 hours before departure: 70% of
          the ticket amount will be refunded.
        </li>
        <li className="mt-1-5">Cancellation within 24 hours of departure: No refund will be provided.</li>
        <li className="mt-1-5">
          Rescheduling 72 hours before departure: A fee equivalent to 20% of the ticket amount will apply.
        </li>
        <li className="mt-1-5">
          Rescheduling between 24 to 72 hours before departure: A fee equivalent
          to 30% of the ticket amount will apply.
        </li>
        <li className="mt-1-5">Rescheduling within 24 hours of departure: Rescheduling is not permitted.</li>
      </ul>
      <ul>
        <br></br>

        <hr></hr>

        <br></br>
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>
          Baggage Policy
        </li>
        <li className="mt-1-5">
          Hand Luggage: One carry-on bag is allowed with a personal item. The
          carry-on bag, including handles and wheels, must not exceed 22 x 14 x
          9 inches (56 x 36 x 23 cm) and a maximum weight of 10 kg.
        </li>
        <li className="mt-1-5">
          Checked Baggage: 
          <ul>
            <li>First checked bag: Fee of ₹1,200 for up to 20 kg.</li>
            <li>Second checked bag: Fee of ₹1,800 for up to 20 kg.</li>
            <li>Third checked bag: Fee of ₹3,000 for up to 10 kg.</li>
          </ul>
          All checked baggage must be pre-booked online. Extra checked baggage cannot be added at the time of check-in. Maximum baggage dimensions are 62 inches (length + width + height), and each bag must not exceed 20 kg.
        </li>
        <li className="mt-1-5">
          A maximum of three bags is allowed per person.
        </li>
      </ul>
      <ul>
        <br></br>

        <hr></hr>

        <br></br>
        <li style={{ listStyleType: "none", fontWeight: "bold" }}>
          Visa Requirements
        </li>
        <li
          style={{
            listStyleType: "none",
            textDecoration: "underline",
            fontWeight: 500,
          }}
          className="mt-1-5 "
        >
          For Indian passport holders entering Sri Lanka:
        </li>
        <li className="mt-1-5">
          A passport with at least 6 months (180 days) validity from the travel date is required.
        </li>
        <li className="mt-1-5">
          A return ticket or onward ticket (by Sea or Air) from Sri Lanka is required.
        </li>
        <li
          className="mt-1-5"
          style={{
            listStyleType: "none",
            textDecoration: "underline",
            fontWeight: 500,
          }}
        >
          For Sri Lankan passport holders to enter India:
        </li>
        <li className="mt-1-5">
          A valid passport with at least 6 months (180 days) validity from the entry date is required.
        </li>
        <li className="mt-1-5">
          A valid visa issued by an Indian High Commission or Embassy is required. First-time entry with a new e-visa is not permitted through Nagapattinam port; however, subsequent entries using the same e-visa are allowed.
        </li>
        <li
          className="mt-1-5"
          style={{
            listStyleType: "none",
            textDecoration: "underline",
            fontWeight: 500,
          }}
        >
          For all other international passport holders to enter India:
        </li>
        <li className="mt-1-5">
          A passport with a minimum of 6 months (180 days) validity from the date of entry is required.
        </li>
        <li className="mt-1-5">
          Travelers must possess a valid visa unless their country is exempt from the visa requirement.
        </li>
        <li
          className="mt-1-5"
          style={{
            listStyleType: "none",
            textDecoration: "underline",
            fontWeight: 500,
          }}
        >
          For all other international passport holders to enter Sri Lanka:
        </li>
        <li className="mt-1-5">
          A valid passport with at least 6 months (180 days) validity from the date of entry is required.
        </li>
        <li className="mt-1-5">
          Travelers from visa-on-arrival countries do not need a visa; all others must secure a valid visa to enter Sri Lanka.
        </li>
        <li className="mt-1-5">
          All tourists or visitors must have a valid return or onward journey ticket.
        </li>
      </ul>
    </div>
  );
}
