import { useState } from "react";
import Loader from '../icons/Loader';
import { booking } from "../../api/book";
import { extraLuggagePrice } from "../../api/api";
export default function ExtraLuggage({ count, setAmountBreakeDown, setProceedPaymentDts, proceedPaymentDts, data, setLugInfo, lugInfo, setShow }) {
    const [weight, setWeight] = useState({ oneWay: 0, return: 0 })
    const [validateErr, setValidateErr] = useState({ oneWay: true, return: true });
    const [submit, setSubmit] = useState(false);
    const maxWeight = data.oneWayData.maxLuggage;
    const returnMaxWeight = data.round ? data.roundData.maxLuggage : 0;
    const isRound = data.round;
    function handleChange(e) {
        const name = e.target.name;
        const val = Number(e.target.value);

        if (val < 0) setWeight({ ...weight, [name]: 0 })
        else {
            if (name == 'oneWay') {
                if ((val % 1) != 0) setValidateErr({ ...validateErr, oneWay: "Please enter integer value" })
                else {
                    if (val > maxWeight) setValidateErr({ ...validateErr, oneWay: `maximum ${maxWeight} kg` })
                    // else if (val > maxWeight) setValidateErr({ ...validateErr, oneWay: `maximum shoul be ${maxWeight}` })
                    else {
                        setValidateErr({ ...validateErr, oneWay: false, return: false })
                        setWeight({ ...weight, [e.target.name]: Number(e.target.value) });
                    }
                }
            } else {
                if ((val % 1) != 0) setValidateErr({ ...validateErr, return: "Please enter integer value" })
                else {
                    if (val > returnMaxWeight) setValidateErr({ ...validateErr, return: `maximum ${returnMaxWeight}  kg` })
                    else {
                        setValidateErr({ ...validateErr, return: false });
                        setWeight({ ...weight, [e.target.name]: Number(e.target.value) });
                    }
                }
            }
        }

    }

    function handleSubmit() {
        if ((weight.oneWay < 0) || (weight.return < 0) || (weight.oneWay + weight.return) <= 0) {
            setProceedPaymentDts({
                ...proceedPaymentDts,
                paymentAmount: proceedPaymentDts.paymentAmount - (lugInfo.oneWayAmount + lugInfo.returnAmount)
            })
            setLugInfo({ returnAmount: 0, oneWayAmount: 0, oneWayWeight: 0, returnWeight: 0 })
        }
        else {
            setSubmit(true)
            const input = {
                weight: weight.oneWay,
                travelDate: new Date(data.oneWayData.dateOfTravel).toLocaleDateString('en-GB'),
                fromPort: data.oneWayData.voyageFrom,
                returnWeight: weight.return,
                returnTravelDate: data.round ? new Date(data.roundData.dateOfTravel).toLocaleDateString('en-GB') : '',
                returnFromPort: data.round ? data.roundData.voyageFrom : '',
                count: count
            }
            // setLugInfo({ ...lugInfo, oneWayWeight: weight.oneWay, returnWeight: weight.return })
            extraLuggagePrice(input).then(
                data => {
                    if (data) {
                        setLugInfo({
                            oneWayAmount: data.oneWay.chargePriceWithTax,
                            oneWayWeight: input.weight,
                            returnAmount: data.returnWay ? data.returnWay.chargePriceWithTax : 0,
                            returnWeight: input.returnWeight
                        })
                        if (isRound) {
                            proceedPaymentDts.paymentAmount -= (lugInfo.oneWayAmount + lugInfo.returnAmount);
                            setProceedPaymentDts({
                                ...proceedPaymentDts,
                                paymentAmount: proceedPaymentDts.paymentAmount + data.oneWay.chargePriceWithTax + data.returnWay.chargePriceWithTax,
                                weight: input.weight,
                                returnWeight: input.returnWeight,
                            })
                            setAmountBreakeDown({
                                oneWay: {
                                    chargeCurrency: data.oneWay.chargeCurrency,
                                    chargePrice: data.oneWay.chargePrice,
                                    chargePriceWithTax: data.oneWay.chargePriceWithTax,
                                    amountBreakDown: data.oneWay.amountBreakDown
                                },
                                roundWay: {
                                    chargeCurrency: data.returnWay.chargeCurrency,
                                    chargePrice: data.returnWay.chargePrice,
                                    chargePriceWithTax: data.returnWay.chargePriceWithTax,
                                    amountBreakDown: data.returnWay.amountBreakDown
                                }
                            })
                        } else {
                            proceedPaymentDts.paymentAmount -= lugInfo.oneWayAmount;
                            setProceedPaymentDts({
                                ...proceedPaymentDts,
                                paymentAmount: proceedPaymentDts.paymentAmount + data.oneWay.chargePriceWithTax,
                                weight: input.weight
                            })
                            setAmountBreakeDown({
                                oneWay: {
                                    chargeCurrency: data.oneWay.chargeCurrency,
                                    chargePrice: data.oneWay.chargePrice,
                                    chargePriceWithTax: data.oneWay.chargePriceWithTax,
                                    amountBreakDown: data.oneWay.amountBreakDown
                                },
                                roundWay: {
                                    amountBreakDown: []
                                }
                            })
                        }

                    }
                    setSubmit(false);
                    setTimeout(() => setShow(false), 2000);
                }
            )
        }
    }
    return (
        <div className="p-c p-1" style={{ maxWidth: '416px', height: "fit-content" }}>
            <div className="bg-white p-2 br-r-1">
                <h1 className="fs-20px b-500 primary txt-a-c">Provide Extra Luggage Quantity</h1>
                <p className="gray mtb-1">Enhance your journey by adding extra luggage. Specify the quantity you need for a more convenient and comfortable travel experience.</p>
                {/* <p className="txt-a-c mtb-1"><span className="primary">USD 5</span><span> / kg </span></p> */}
                <div className="p-r br-r-12 p-1 m-a" style={{ background: 'var(--inputGray)', width: 'fit-content', minWidth: "121px", height: "68px" }}><span className="b-500 fs-1-5">{submit ? <Loader color="#DF7435" /> : data.oneWayData.chargeCurrency + ' ' + (lugInfo.oneWayAmount + lugInfo.returnAmount).toLocaleString()}</span></div>

                {data.round
                    ? <div className="d-f c-gap-1 mtb-1">
                        <div>
                            <label htmlFor="" className="b-500 primary">{data.oneWayData.voyageFrom} - {data.oneWayData.voyageTo}</label>
                            <input type="number" onChange={handleChange} min="0" name="oneWay" placeholder="Kg" className="pl-1 br-n br-r-8 mt-8px pl-1" style={{ background: 'var(--inputGray)', height: "48px", minWidth: 0, width: '100%' }} /> <br />
                            <span className="red">{validateErr.oneWay}</span>
                        </div>
                        <div className="txt-a-r">
                            <label htmlFor="" className="b-500 primary">{data.roundData.voyageFrom} - {data.roundData.voyageTo}</label>
                            <input type="number" onChange={handleChange} min="0" name="return" placeholder="Kg" className="pl-1 br-n br-r-8 mt-8px pl-1" style={{ background: 'var(--inputGray)', height: "48px", minWidth: 0, width: '100%' }} /> <br />
                            <span className="red">{validateErr.return}</span>
                        </div>
                    </div>
                    : <div className="mb-2 mt-1">
                        <label className="b-500" htmlFor="">Extra Luggage(Kg)</label><br />
                        <input type="number" onChange={handleChange} min="0" name="oneWay" placeholder="Extra Luggage(Kg)" className="w-100 br-n br-r-8 mt-8px pl-1" style={{ background: 'var(--inputGray)', height: "48px" }} /> <br />
                        <span className="red">{validateErr.oneWay}</span>
                    </div>}

                <div className="txt-a-c">
                    <button onClick={handleSubmit} className="p-r btn pointer" style={{ opacity: `${validateErr.oneWay || validateErr.return || submit ? .4 : 1}` }} disabled={validateErr.oneWay || validateErr.return}>Confirm</button><br />
                    <button onClick={() => { setShow(false); }} className="p-r br-btn pointer mt-1-5" style={{ width: '171px' }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}