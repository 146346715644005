import { useState } from "react"
import { useNavigate } from "react-router-dom";
export default function Tap() {
    const navigate = useNavigate();
    const [currentTap, setCurrentTap] = useState({
        tap: 1,
        blue: "#DF7435",
        gray: "#848484"
    });
    const style = {
        container: {
            position: "fixed",
            height: "60px",
            width: '100%',
            bottom: 0,
            zIndex: 2,
            justifyContent: 'space-around',
            boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.25)'
        }
    }
    return (
        <div>
            <div className="d-f f-a-c bg-white" style={style.container}>
                <div onClick={() => { setCurrentTap({ ...currentTap, tap: 1 }); navigate('/dashboard') }} className="pointer d-f f-col f-a-c">
                    <DashboardIcon currentTap={currentTap} />
                </div>
                <div onClick={() => { setCurrentTap({ ...currentTap, tap: 2 }); navigate('/home') }} className="pointer d-f f-col f-a-c">
                    <NewBookingIcon currentTap={currentTap} />
                </div>
                <div onClick={() => { setCurrentTap({ ...currentTap, tap: 3 }); navigate('/account') }} className="pointer d-f f-col f-a-c">
                    <AccountIcon currentTap={currentTap} />
                </div>
            </div>
            {/* {show && <div className="overlay" onClick={() => setShow(false)}>

                <div className="txt-a-c p-1 p-c logout-popup" style={{ height: "fit-content" }}>
                    <div className="p-1 bg-white br-r-12">
                        <p>Are you want to logout?</p>
                        <div className="mt-1">
                            <button onClick={() => setShow(false)} className="pointer br-btn mr-1" style={{ width: "60px", height: "fit-content" }}>No</button>
                            <button onClick={() => { localStorage.clear(); window.location.assign('/'); }} className="pointer btn" style={{ width: "60px", height: "fit-content" }}>yes</button>
                        </div>
                    </div>
                </div>
            </div>} */}
        </div>
    )
}

function DashboardIcon({ currentTap }) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3535 32H3.17883C1.42321 32 0 30.5768 0 28.8212V20.6465C0 18.8909 1.42321 17.4677 3.17883 17.4677H11.3535C13.1091 17.4677 14.5323 18.8909 14.5323 20.6465V28.8212C14.5323 30.5768 13.1091 32 11.3535 32Z" fill={currentTap.tap == 1 ? currentTap.blue : currentTap.gray} />
            <path d="M28.8213 32H20.6466C18.891 32 17.4678 30.5768 17.4678 28.8212V20.6465C17.4678 18.8909 18.891 17.4677 20.6466 17.4677H28.8213C30.5769 17.4677 32.0001 18.8909 32.0001 20.6465V28.8212C32.0001 30.5768 30.5769 32 28.8213 32Z" fill={currentTap.tap == 1 ? currentTap.blue : currentTap.gray} />
            <path d="M11.3535 14.5323H3.17883C1.42321 14.5323 0 13.1091 0 11.3535V3.17878C0 1.42317 1.42321 -4.48227e-05 3.17883 -4.48227e-05H11.3535C13.1091 -4.48227e-05 14.5323 1.42317 14.5323 3.17878V11.3535C14.5323 13.1091 13.1091 14.5323 11.3535 14.5323Z" fill={currentTap.tap == 1 ? currentTap.blue : currentTap.gray} />
            <path d="M28.8213 14.5323H20.6466C18.891 14.5323 17.4678 13.1091 17.4678 11.3535V3.17878C17.4678 1.42317 18.891 -4.48227e-05 20.6466 -4.48227e-05H28.8213C30.5769 -4.48227e-05 32.0001 1.42317 32.0001 3.17878V11.3535C32.0001 13.1091 30.5769 14.5323 28.8213 14.5323Z" fill={currentTap.tap == 1 ? currentTap.blue : currentTap.gray} />
        </svg>
    )
}
function NewBookingIcon({ currentTap }) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="16" fill={currentTap.tap == 2 ? currentTap.blue : currentTap.gray} />
            <path d="M23 15H17V9C17 8.73478 16.8946 8.48043 16.7071 8.29289C16.5196 8.10536 16.2652 8 16 8C15.7348 8 15.4804 8.10536 15.2929 8.29289C15.1054 8.48043 15 8.73478 15 9V15H9C8.73478 15 8.48043 15.1054 8.29289 15.2929C8.10536 15.4804 8 15.7348 8 16C8 16.2652 8.10536 16.5196 8.29289 16.7071C8.48043 16.8946 8.73478 17 9 17H15V23C15 23.2652 15.1054 23.5196 15.2929 23.7071C15.4804 23.8946 15.7348 24 16 24C16.2652 24 16.5196 23.8946 16.7071 23.7071C16.8946 23.5196 17 23.2652 17 23V17H23C23.2652 17 23.5196 16.8946 23.7071 16.7071C23.8946 16.5196 24 16.2652 24 16C24 15.7348 23.8946 15.4804 23.7071 15.2929C23.5196 15.1054 23.2652 15 23 15Z" fill="white" />
        </svg>
    )
}
function AccountIcon({ currentTap }) {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 22.2618C21.8296 22.2618 26.5555 17.2783 26.5555 11.1309C26.5555 4.98346 21.8296 0 16 0C10.1704 0 5.44458 4.98346 5.44458 11.1309C5.44458 17.2783 10.1704 22.2618 16 22.2618Z" fill={currentTap.tap == 3 ? currentTap.blue : currentTap.gray} />
            <path d="M0 31.9996C1.62217 29.0388 3.95468 26.5802 6.76321 24.8709C9.57173 23.1615 12.7574 22.2617 16.0001 22.2617C19.2428 22.2618 22.4284 23.1617 25.2369 24.8711C28.0454 26.5804 30.3779 29.0391 32 32" fill={currentTap.tap == 3 ? currentTap.blue : currentTap.gray} />
        </svg>
    )
}
