export default function PrivacyPolicy() {
    return (
        <div className="sm-p-1 br-r-1 p-1 m-a" style={{ maxWidth: "896px", boxShadow: ' 0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
            <h1 className="fs-20px b-500">Privacy Policy for Subham Ferry Ticket Booking System</h1>
            <p className="mt-1-5">Welcome to Subham, a ferry ticket booking service for travel between India and Sri Lanka. We are committed to protecting the privacy and security of our customers' personal information. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your personal information when you use our booking system. By using our services, you agree to the terms of this Privacy Policy.</p>
            
            <h1 className="b-700 mt-1-5">1. Information We Collect</h1>
            <p className="mt-1"><span className="b-600">Personal Identification Information:</span> Name, email address, phone number, and other information you provide when registering or booking tickets.</p>
            <p className="mt-1"><span className="b-600">Payment Information:</span> Credit card details and other payment-related information (which are processed through secure payment gateways).</p>
            <p className="mt-1"><span className="b-600">Travel Information:</span> Travel dates, destination, and other details related to your ferry booking.</p>
            <p className="mt-1"><span className="b-600">Technical Information:</span> Information about your device, browser, IP address, and other technical data used to access our website or mobile app.</p>

            <h1 className="b-700 mt-1-5">2. How We Use Your Information</h1>
            <p className="mt-1">We use your personal information for the following purposes:</p>
            <ul>
                <li>Processing Ferry Ticket Bookings</li>
                <li>Managing Travel Arrangements</li>
                <li>Payment Processing</li>
                <li>Communication</li>
                <li>Improving Services</li>
                <li>Offering Personalized Experiences</li>
            </ul>
            <p className="mt-1">By using our service, you consent to the collection and use of your personal information for these purposes. If you do not agree with any aspect of this use, you may choose to discontinue the use of our service.</p>

            <h1 className="b-700 mt-1-5">3. Sharing of Information</h1>
            <p className="mt-1">We may share your personal information with trusted third-party service providers who perform services on our behalf. This includes payment processing, data analysis, and customer service.</p>
            <p className="mt-1">We do not sell, rent, or share your personal information with third parties for their marketing purposes without your explicit consent. Additionally, we may disclose your personal information when required to do so by law or to protect our rights or safety.</p>

            <h1 className="b-700 mt-1-5">4. Data Security</h1>
            <p className="mt-1">We implement a variety of security measures to protect your personal information, including encryption, secure payment processing, and regular security audits. However, no method of transmission over the internet or electronic storage is 100% secure. If you believe your account or personal information has been compromised, please contact us immediately.</p>

            <h1 className="b-700 mt-1-5">5. Cookies and Tracking Technologies</h1>
            <p className="mt-1">We use cookies and similar tracking technologies to collect information about your activity on our service and enhance your user experience.</p>
            <p>Cookies allow us to track activity, store information, and provide a more personalized experience. You can manage cookie settings through your browser, but doing so may affect your experience with our service.</p>

            <h1 className="b-700 mt-1-5">6. Your Rights</h1>
            <p className="mt-1">You have the right to access, update, or delete your personal information. You can also opt out of promotional communications. To exercise any of these rights, please contact us at <span className="primary">customercare@sailsubham.com</span>.</p>

            <h1 className="b-700 mt-1-5">7. Changes to This Privacy Policy</h1>
            <p className="mt-1">We may update this Privacy Policy from time to time. Changes will be posted on this page and will become effective immediately upon publication.</p>

            <h1 className="b-700 mt-1-5">Contact Us</h1>
            <p className="mt-1">If you have any questions about this Privacy Policy, please contact us at <span className="primary">customercare@sailsubham.com</span>.</p>
            <p className="mt-1-5">Subham Ferry respects your privacy and is committed to protecting it through our compliance with this policy.</p>
        </div>
    )
}
