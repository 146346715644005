export default function TermsAndConditions({ setShowTermsConditions, isRegister = false }) {
  return (
      <div className="br-r-1 bg-white m-a h-100" style={{ overflow: 'auto', maxWidth: "896px", padding: "2rem 1.5rem", boxShadow: ' 0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
          <h1 className="fs-25px b-600" style={{ textAlign: 'center' }}>Terms & Conditions for Sail Subham</h1>
          <p className="mt-1-5">Welcome to Sail Subham, the official ticket booking platform for Subham Ferry. These Terms and Conditions outline the rules and regulations for the use of our web and mobile application services, available on both Android and iOS platforms. By accessing and using Sail Subham, you agree to comply with and be bound by the following terms. Please read them carefully.</p>
          <ol className="pl-1">
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">1. Introduction</h2>
                  <ul>
                      <li>1.1 These terms and conditions govern your use of our website.</li>
                      <li>1.2 By accessing our website, you accept these terms in full. If you disagree with any part, you must not use our website.</li>
                      <li>1.3 By registering or using our services, you explicitly agree to these terms and conditions.</li>
                      <li>1.4 Users must be at least 18 years old to use the website. By using our services, you confirm this requirement.</li>
                      <li>1.5 Our website does not use cookies.</li>
                  </ul>
              </li>
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">2. License to Use the Website</h2>
                  <ul>
                      <li>(a) You may view, download for caching, or print pages for personal or business use.</li>
                      <li>(b) You must not republish, sell, sub-license, exploit, or redistribute website materials without authorization.</li>
                      <li>(c) Any unauthorized access circumvention or modification of content is prohibited.</li>
                  </ul>
              </li>
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">3. Prohibited Uses of the Website</h2>
                  <ul>
                      <li>(a) Causing damage or impairing website performance is prohibited.</li>
                      <li>(b) Engaging in illegal, fraudulent, or harmful activities is not allowed.</li>
                      <li>(c) Using spyware, viruses, or malicious software is strictly forbidden.</li>
                      <li>(d) Unauthorized data collection activities like scraping or data mining are prohibited.</li>
                  </ul>
              </li>
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">4. Registration and Accounts</h2>
                  <ul>
                      <li>(a) Account registration requires a completed form and verification email confirmation.</li>
                      <li>(b) You are responsible for maintaining account confidentiality and reporting unauthorized access.</li>
                  </ul>
              </li>
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">5. User Login Details</h2>
                  <ul>
                      <li>(a) User IDs must not mislead or impersonate others.</li>
                      <li>(b) Keep your password confidential and report any breaches immediately.</li>
                  </ul>
              </li>
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">6. Cancellation and Suspension of Accounts</h2>
                  <ul>
                      <li>(a) We reserve the right to suspend or cancel your account at any time for violations.</li>
                      <li>(b) You may cancel your account using the provided control panel.</li>
                  </ul>
              </li>
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">7. Your Content License</h2>
                  <ul>
                      <li>(a) By submitting content, you grant us the rights to use and process it per our policies.</li>
                      <li>(b) Content must not violate any laws or infringe others’ rights.</li>
                  </ul>
              </li>
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">8. Limitation of Liability</h2>
                  <ul>
                      <li>(a) We do not guarantee the completeness, accuracy, or availability of the website content.</li>
                      <li>(b) We reserve the right to modify or discontinue services without liability.</li>
                  </ul>
              </li>
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">9. Governing Law and Jurisdiction</h2>
                  <ul>
                      <li>(a) These terms are governed by Indian law.</li>
                      <li>(b) Any disputes shall fall under the exclusive jurisdiction of Tamil Nadu courts.</li>
                  </ul>
              </li>
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">10. Statutory and Regulatory Disclosures</h2>
                  <ul>
                      <li>Registered under Ministry of Corporate Affairs, India.</li>
                      <li>Registration Number: U60230TN2011PTC079906.</li>
                      <li>GST Number: 33AAPCS5550D1ZD.</li>
                  </ul>
              </li>
              <li className="b-700 mt-1-5">
                  <h2 className="b-700">11. Contact Details</h2>
                  <ul>
                      <li>Address: SF, 391/3BI,5A,6A,4A, Ezhichur, Shri Perambudur Taluk, Tamil Nadu, India - 631605.</li>
                      <li>Email: customercare@sailsubham.com</li>
                      <li>Phone: +91 7448843535</li>
                  </ul>
              </li>
          </ol>
          {isRegister && (
              <div className="txt-a-c mt-2">
                  <button className="btn pointer" onClick={() => setShowTermsConditions(false)}>Continue</button>
              </div>
          )}
      </div>
  );
}
