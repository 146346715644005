export default function ContactUs() {
    return (
        <div className="br-r-1 bg-white m-a h-100" style={{ overflow: 'auto', maxWidth: "896px", padding: "2rem 1.5rem" }}>
            <h1 className="fs-20px b-500 txt-a-c">Contact Sail Subham</h1>
            <p className="txt-a-c mt-1">
                We're here to assist you on your maritime journey.
                For any inquiries, booking assistance, or general information,
                please don't hesitate to contact us.
            </p>
            <div className="d-f f-wrap f-j-c c-gap-2 r-gap-2 mt-2">
                <div className="br-r-12 p-1" style={{ boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', width: "384px", height: "237px" }}>
                    <div className="d-f f-spb">
                        <h1 className="fs-20px b-500">Speak To Us</h1>
                        <span className="br-r-8 white fs-14px d-f f-a-c" style={{ backgroundColor: "#11B800", padding: "0 10px" }}>Call 24x7</span>
                    </div>
                    <p className="mt-1-5">Dial our number, and speak to us directly.
                        Your journey with Sail Subham matters to us.</p>
                    <div className="d-f f-spb mt-2">
                        <span>India / International</span>
                        <span className="b-600">+91 744 884 3535</span>
                    </div>
                </div>
                <div className="br-r-12 p-1" style={{ boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', width: "384px", height: "237px" }}>
                    <div className="d-f f-spb">
                        <h1 className="fs-20px b-500">Write To Us</h1>
                        <span className="br-r-8 white fs-14px d-f f-a-c" style={{ backgroundColor: "#11B800", padding: "0 10px" }}>Reply within 24hrs</span>
                    </div>
                    <p className="mt-1-5">Drop us a line, and our dedicated team will get back to you in 24 hours.</p>
                    <div className="d-f f-spb mt-2">
                        <span>All Queries </span>
                        <span className="b-600">customercare@sailsubham.com</span>
                    </div>
                </div>
                <div className="br-r-12 p-1" style={{ boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', width: "384px", height: "237px" }}>
                    <div className="d-f f-spb">
                        <h1 className="fs-20px b-500">Reg. Admin Office</h1>
                        <span className="br-r-8 white fs-14px d-f f-a-c" style={{ backgroundColor: "#DF7435", padding: "0 10px" }}>Tamil Nadu</span>
                    </div>
                    <p className="mt-1-5">
                        Subham Freight Carriers India Pvt Ltd<br />
                        SF,391/3BI,5A,6A,4A, Ezhichur,<br />
                        Shri Perambudur Taluk,<br />
                        Tamil Nadu, India - 631605<br />
                    </p>
                </div>
                <div className="br-r-12 p-1" style={{ boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)', width: "384px", height: "237px" }}>
                    <div className="d-f f-spb">
                        <h1 className="fs-20px b-500">Operations Office</h1>
                        <span className="br-r-8 white fs-14px d-f f-a-c" style={{ backgroundColor: "#DF7435", padding: "0 10px" }}>Trichy</span>
                    </div>
                    <p className="mt-1-5">
                        Subham Freight Carriers India Pvt Ltd<br />
                        Principal Place of Business:<br />
                        Trichy, Tamil Nadu, India<br />
                    </p>
                </div>
            </div>
        </div>
    );
}
