import { Link } from "react-router-dom";
import { CloseEye, OpenEye } from "../icons/eye";
import { useState } from "react";
import Loader from "../icons/Loader";
import Err from "./Err";
export default function Account() {
    const [showConfirm, setShowConfirm] = useState(false)
    if (!localStorage.getItem('token')) return window.location.assign('/')
    if (showConfirm) return <ConfirmDeletion />
    else return (
        <div className="p-c p-2 w-100 h-100 sm-p-1">
            <div className="fade p-r d-f f-col f-a-c r-gap-1 br-r-1 w-100 h-100 f-j-c" style={{ boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                <div className="d-f f-col r-gap-1 f-a-c">
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M40 0C29.395 0.0118577 19.2277 4.22994 11.7288 11.7288C4.22994 19.2277 0.0118577 29.395 0 40C0.0177686 50.6032 4.23775 60.767 11.7354 68.2646C19.233 75.7623 29.3968 79.9822 40 80C50.6087 80 60.7828 75.7857 68.2843 68.2843C75.7857 60.7828 80 50.6087 80 40C80 29.3913 75.7857 19.2172 68.2843 11.7157C60.7828 4.21427 50.6087 0 40 0ZM64.3296 63.0816C62.0831 58.5504 58.6151 54.7372 54.3167 52.0722C50.0183 49.4072 45.0607 47.9964 40.0033 47.9991C34.9458 48.0017 29.9897 49.4177 25.6941 52.0873C21.3985 54.7568 17.9345 58.5737 15.6928 63.1072C9.71173 56.904 6.37901 48.617 6.4 40C6.4 31.0887 9.93999 22.5424 16.2412 16.2412C22.5424 9.93999 31.0887 6.4 40 6.4C48.9113 6.4 57.4576 9.93999 63.7588 16.2412C70.06 22.5424 73.6 31.0887 73.6 40C73.62 48.6049 70.296 56.881 64.3296 63.0816ZM40 16C37.152 16 34.3679 16.8445 31.9998 18.4268C29.6317 20.0091 27.786 22.2581 26.6961 24.8894C25.6062 27.5206 25.3211 30.416 25.8767 33.2093C26.4323 36.0026 27.8038 38.5685 29.8177 40.5823C31.8315 42.5962 34.3974 43.9677 37.1907 44.5233C39.984 45.0789 42.8794 44.7938 45.5106 43.7039C48.1419 42.614 50.3909 40.7683 51.9732 38.4002C53.5555 36.0321 54.4 33.2481 54.4 30.4C54.4 28.509 54.0275 26.6364 53.3039 24.8894C52.5802 23.1423 51.5195 21.5548 50.1823 20.2177C48.8452 18.8805 47.2577 17.8198 45.5106 17.0961C43.7635 16.3725 41.891 16 40 16Z" fill="#DF7435" />
                    </svg>
                    <p>{localStorage.getItem('username')}</p>
                    <p><span>Email: </span>{localStorage.getItem('email')}</p>
                    <p><span>Phone Number: </span>{localStorage.getItem('phoneNumber')}</p>
                </div>
                <span className="txt-dec-u primary pointer" onClick={() => {
                    localStorage.clear();
                    window.location.assign('/');
                }}>Logout</span>
                <Link className="primary" to="/change-password">Change Password</Link>
                <p className="red txt-dec-u pointer" onClick={() => setShowConfirm(true)}>Account Termination</p>
            </div>
            {showConfirm && <div className="overlay"><ConfirmDeletion /></div>}
        </div>

    )
}

function ConfirmDeletion() {
    const [showPsd, setShowPsd] = useState(false);
    if (showPsd) return <GetPassword />
    else return (
        <div className="p-c p-2 w-100 h-100 d-f f-j-c f-a-c">
            <div className="fade p-1-5 p-r d-f br-r-1 w-100 h-100" style={{ maxWidth: "416px", maxHeight: "438px", boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                <div className="d-f f-col f-spb">
                    <h1 className="primary b-500 txt-a-c">Account Deletion Confirmation!</h1>
                    <p className="txt-a-c gray">
                        By confirming, you acknowledge that you are leaving this platform. Are you sure you want to proceed? Please note that this action is irreversible, and all associated data will be permanently removed. Please consider this decision carefully before confirming.
                    </p>
                    <div className="d-f f-col r-gap-2 f-a-c">
                        <button onClick={() => window.location.assign('/account')} className="btn pointer">Cancel</button>
                        {/* <button className="br-btn pointer" style={{ width: "171px" }} onClick={() => setShowPsd(true)} > Confirm</button> */}
                        <button className="br-btn pointer" style={{ width: "171px" }} onClick={() => window.location.assign('/accountDeletion')} > Confirm</button>
                    </div>
                </div>
            </div>
        </div >
    )
}

function GetPassword() {
    const [password, setPassword] = useState('');
    const [show, setShow] = useState(true);
    const [submit, setSubmit] = useState(false);
    const [resErr, setResErr] = useState({ message: "" });
    async function handleSumbit(e) {
        e.preventDefault();
        setSubmit(true);
        try {
            const response = await fetch(process.env.REACT_APP_DOAMIN + '/Authenticate/accountDeletion', {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ password: password })
            });
            const result = await response.json();
            if (response.status == 200) {
                localStorage.clear();
                window.location.assign('/');
            }
            else {
                throw result
            }
        } catch (err) {
            setSubmit(false);
            setResErr({ message: err.message })
        }
    }
    return (
        <div className="p-c p-2 w-100 h-100 d-f f-j-c f-a-c">
            <div className="fade p-2 d-f f-col p-r br-r-1 w-100 h-100" style={{ maxWidth: "416px", maxHeight: "422px", boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)' }}>
                <div>
                    <h1 className="fs-20px primary b-500">Enter your Password</h1>
                    <p className="gray mt-1">Please provide your Password
                        to terminate your account.</p>
                </div>
                <div className="mt-1-5">
                    <label htmlFor="" className="b-500">Password</label><br />
                    <div className="mt-8px d-f" style={{ position: 'relative' }}>
                        <input onChange={(e) => setPassword(e.target.value)} style={{ padding: "14px 24px" }} className="fs-14px input-gray gray w-100 h-48 br-r-8 br-n" type={show ? 'password' : 'text'} name="password" placeholder="Password" required />
                        <span className="pointer d-f h-100 f-a-c" onClick={() => setShow(!show)} style={{ position: 'absolute', right: 0, marginRight: '12px' }}>
                            {show ? <CloseEye /> : <OpenEye />}
                        </span>
                    </div>
                    <div className="d-f f-col mt-2 r-gap-2 f-a-c">
                        <button onClick={() => window.location.assign('/account')} className="btn pointer">Cancel</button>
                        <button className="p-r br-btn pointer" style={{ width: "171px" }} onClick={handleSumbit}>Confirm {submit && <Loader color={'black'} />}</button>
                    </div>
                </div>
            </div>
            {resErr.message && <div className="overlay"><Err data={resErr} click={() => setResErr({ message: "" })} /></div>}
        </div>
    )
}